import React, { useState, useEffect } from "react"
import useApi from "storybook-dashboard/utils/fetching";
import { BasicSpinner } from "storybook-dashboard/spinners/basicSpinner"
import { ErrorBoundary } from "storybook-dashboard/errors";
import IssuesRAGPanel from "storybook-dashboard/dashboard/ragPanels/issues"
import IndicatorsRAGPanel, { orderIndicators } from "storybook-dashboard/dashboard/ragPanels/indicators"
import IndicatorsBarChart from "storybook-dashboard/dashboard/charts/verticalBar"
import ProjectReportCommitStatus from "storybook-dashboard/dashboard/project/reportStatus"
import IndicatorPerformanceTable from "storybook-dashboard/dashboard/performanceTable";
import InputValuesTable from "storybook-dashboard/dashboard/project/inputValues";
import DataExplorer from "storybook-dashboard/dashboard/components/dataExplorer";
import Moment from "moment"
import Im from "immutable"


export const yearFilters = (year) => {
  let key = "commit__reporting_period__endDate"
  let refDate = year ? new Moment({year}) : new Moment()
  return Im.fromJS({
    [`${key}__gt`]: refDate.utc().startOf("year").toISOString(),
    [`${key}__lte`]: refDate.utc().add(1, "years").startOf("year").toISOString()
  })
}


const DEFAULT_PERIOD = "year"

function Hider({children, hide}) {
    return hide ? null : children
}

const explorerOptionsProject = {
  metric_values: {
      text: "Performance breakdown table",
      component: IndicatorPerformanceTable
  },
  performance: {
      text: "Input values table",
      component: InputValuesTable}
}

const explorerOptionsReportingPackage = {
  performance: {
      text: "Input values table",
      component: InputValuesTable}
}

export default function DashboardComponents({projectId, trackerId, refId, indicatorOrder, iconPath}) {
    let [selectedIssue, setSelectedIssue] = useState(null)
    let [selectedIndicatorIds, setSelectedIndicatorIds] = useState(Im.Map())
  
    // Reset the selected indicators to none if the issue selection changes
    useEffect(() => {
      setSelectedIndicatorIds(Im.Map())
    }, [selectedIssue])

    // Get the last period with a report submission
    let { data: reports, isLoading } = useApi("/api/tracker/{trackerId}/ref/{refId}/commit/", {trackerId, refId})

    if (refId && isLoading) { return (<BasicSpinner />) }

    let latestReport = reports
      ?.filter(i => !i.get("is_staging"))
      ?.sortBy(i => i.getIn(["reporting_period_data", "startDate"]))
      ?.last() || Im.Map()
    let latestReportYear = (new Moment(latestReport?.getIn(["reporting_period_data", "startDate"]))).year()
    let filters = yearFilters((refId && !(refId == "root")) ? latestReportYear : (latestReportYear - 1))
    //console.log("DashboardComponents got lastReport year", refId, latestReportYear, filters?.toJS())

    // Simplify the selection of indicators to just give a list of objects {uid, name}
    let selectedIndicators = orderIndicators(selectedIndicatorIds.toList(), indicatorOrder)
  
    return (
      <ErrorBoundary>
        <ErrorBoundary>
          <IssuesRAGPanel 
            projectId={projectId} 
            refId={refId}
            iconPath={iconPath}
            selected={selectedIssue}
            setSelected={setSelectedIssue} 
            filters={filters}  
            noRagColor={true}       
          />
        </ErrorBoundary>
  
        <ErrorBoundary>
          <IndicatorsRAGPanel 
            projectId={projectId} 
            refId={refId}
            iconPath={iconPath}
            issue={selectedIssue}
            indicatorOrder={indicatorOrder}
            selected={selectedIndicatorIds}
            setSelected={setSelectedIndicatorIds} 
            filters={filters} 
            noRagColor={true}        
          />
        </ErrorBoundary>
  
        <ErrorBoundary>
          <IndicatorsBarChart 
            projectId={projectId} 
            refId={refId}
            indicators={selectedIndicators}
            period={DEFAULT_PERIOD}
            targetLabels={{target: "ONS UK population", threshold: "Industry Benchmark"}}
          />
        </ErrorBoundary>
  
        <ErrorBoundary>
          <DataExplorer 
              projectId={projectId}
              refId={refId}
              indicators={selectedIndicators}
              options = {refId == "root" ? explorerOptionsProject : explorerOptionsReportingPackage}
          />
        </ErrorBoundary>

        <ErrorBoundary>
          <div className="row">
            <ProjectReportCommitStatus hide={refId != "root"} projectId={projectId}/>
          </div>
        </ErrorBoundary>
      </ErrorBoundary>
    )
  }